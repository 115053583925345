import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../components/SEO'
import Layout from '../components/Layout';
import EgoNav from '../components/EgoNav'

import '../styles/a-propos.scss'


const AboutPage = () => {

  

    return (
        <Layout>
            <SEO title="Marylise crée votre site web"/>
            
            <div className="ego-flex">
            
                 <EgoNav />
                <section className="ego-trip">
                <StaticImage className="image-ego" src="../images/ego-casquette.png" alt="Marylise" objectFit="contain" layout="constrained" />
                   <article>
                     <h2>Web designer diplômée</h2>
                     <p>Lozérienne (48) d'origine, j'ai suivi une formation diplômante de web design au GRETA de Clermont-Ferrand (63) il y a quelques années. Aujourd'hui, je suis web designer freelance dans le Puy-De-Dôme et en Lozère. Grâce à <strong>mes 3 casquettes</strong> ( web, identité visuelle, supports imprimés ), j'accompagne les artisans, commerçants, auto-entrepreneurs, artistes, agriculteurs, établissements touristiques etc. vers plus de visibilité grâce à une communication visuelle efficace et sur-mesure.</p>
                   </article>
                   <article>
                     <h2>Sous la casquette</h2>
                     <p>De nature calme, curieuse et autonome, j'aime apprendre et créer. Mes centres d'intérêt sont par exemple la guitare, le dessin, l'écriture ou encore la programmation web. J'aime me lancer dans de petits projets DIY : déco, consoles de jeux retro... Il m'arrive de passer des heures à visionner des vidéos de passionnés de bois, de science, de modelisme 3D, etc.</p>
                     <p>En plus de mes activités, il est important pour moi de changer d'air de temps en temps : voyages ou excursions dans la nature (canoë, randonnées) font aussi partie de mon emploi du temps.</p>
                   </article>

                    <article id="competences">
                        <h2><i class="fas fa-hamburger"></i></h2>
                        <p>Après un parcours en tant qu'agent administratif pendant plusieurs années, j'ai décidé de prendre un virage vers la créativité et la diversité qui manquaient à ma vie professionnelle. J'ai suivi une formation de web designer durant laquelle j'ai appris la PAO ( publication assistée par ordinateur ), le graphisme, la conception de sites web ainsi que le web marketing. Projets et contrats professionnels m'ont permis de confirmer ces savoirs-faire. Aujourd'hui, mes compétences s'articulent autour de 3 axes principaux.</p>
                        <ul className="jauges">
                            <li><span>Web design</span><div className="progress-bar bar1"></div></li>
                            <li><span>Graphisme, PAO</span><div className="progress-bar bar2"></div></li>
                            <li><span>Développement web</span><div className="progress-bar bar3"></div></li>
                        </ul>
                        
                    </article>


                    <article>
                     <h2>b-com.xyz... le mystère révélé</h2>
                     <p>b -- Barthélémy ( mon nom de famille )<br/>com -- communication<br/>Les anglophiles reconnaitront dans b-com le verbe "to become", devenir.</p>
                     <p>xyz -- une des nouvelles extensions de noms de domaine disponibles et qui a pour symbole le rassemblement, l'unité.</p>

       
                     
                   </article>
                    <article id="valeurs">
                        <h2>Valeurs certifiées</h2>
                    
                        <StaticImage src="../images/nuage.png" alt="valeurs" objectFit="contain" layout="constrained" />
                    </article>

                    
                </section>
            </div>
        </Layout>
    );
};

export default AboutPage;