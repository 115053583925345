import React from 'react';
import styled from 'styled-components';
import Button from './Button'
import COLORS from '../utils/colors'
import SIZES from '../utils/sizes'

const StyledEgoNav = styled.div`
    width:calc(100% / 3 - 72px);
    position:fixed;
    
    z-index:4;
    text-align:justify;
 
    ul{
        margin:48px auto;
        list-style-type:none;
        display:flex;
        flex-direction:column;
        justify-content:space-between;
        height:150px;

        li{
            a{
                text-decoration:none;
                display: block;
                color:${COLORS.secundary};
                background-color:${COLORS.primary};
                padding:16px 24px;
                clip-path: polygon(0 25%, 100% 0%, 100% 75%, 0% 100%);
                text-align:center;
                text-transform:uppercase;
                font-family: "Gilroy ExtraBold", "Catamaran", Arial, Helvetica, sans-serif;
                letter-spacing:1px;
                transition: background-color .3s ease-out;
            }

            a:hover{
                background-color:${COLORS.primaryHover};
            }
      
        }
    }

@media (max-width: ${SIZES.secondBreakPoint}){

    width:100%;
    text-align:center;
    position:relative;
    h1{
        font-size:3rem;
    }

}
   
`


const EgoNav = () => {
    return (
        <StyledEgoNav>
            <h1 className="brush">Marylise,</h1>
            <h3 className="sous-titre">Web designer à casquettes.</h3>
            {/*<ul>
                <li><Link to="/a-propos/#egotrip">Ego Trip</Link></li>
                <li><Link to="/a-propos/#competences">Compétences</Link></li>
                <li><Link to="/a-propos/#valeurs">Valeurs</Link></li>
            </ul>
            <p>Convaincu(e) ?</p>*/}
            <Button className="ego-contact-btn">Contactez-moi</Button>
        </StyledEgoNav>
    );
};

export default EgoNav;